


.gallery-modal {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 85vh;
  min-height: 75vh;
}


.gallery-caption {
  text-align: center;
  color: lightgray;
  margin-top: 5px;
}

.modal-content {
  background: transparent !important;
  border: 0 !important;

}

.container-fluid {
  padding: 0 !important;
}


.footer {
  position: absolute;
  left: 0;
  bottom: -100px;
  width: 100%;
  color: white;
  height: 100px;  
}

.footer>div{
  height: 57px;
}



.primary-color {
  background-color: #6a5acd !important;
}

.secondary-color {
  background-color: #BFDBF7 !important;
}


.bg-img-full {
  background-position: center center;
  background-repeat: 'no-repeat';
  background-size: cover;
  -webkit-transition: background-image 0.5s ease-in-out;
  transition: background-image 0.5s ease-in-out;
}

.first-container {
  height: max(600px, calc(100vh - 74px));
}

.second-container {
  height: max(600px, 100vh);
}


.contact-container {
  display: flex;
}

.contact-card {
  align-self: center;
}

/* Loading screen */

.loading-overlay { 
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-scroll {
  overflow: hidden;
  height: 100%;
}



.spinner {
  margin: 100px auto 0;
  width: 200px;
  text-align: center;
}

.spinner > div {
  width: 30px;
  height: 30px;
  background-color: rgb(255, 255, 255, 0.7);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}







