.about-section {
    display: flex;
    align-items: center;
    max-height: 2000px;
}

@media screen and (max-width: 768px) {
    .about-section {
        background-position: bottom center !important;
        background-image: url("https://u.cubeupload.com/kaleighllama/aboutbgsm.png");
    }
    #about-text {
        align-self: start;
    }
    h1 {
        font-size: 30px;
    }
    .card-body {
        padding: 10px;
    }
    .about-section {
        min-height: 700px;
    }
}

@media screen and (min-width: 768px) {
    .about-section {
        background-image: url("https://u.cubeupload.com/kaleighllama/aboutbglg.png");
    }
}




#portrait {
    align-self: flex-end;
}

#portrait img {
    object-fit: cover;
    width: 100%;
}