.vh-100 {
    height: 100vh !important;
}
  
.vw-100 {
    width: 100vw !important;
}

.opacity-0 {
    opacity:0!important;
}
.opacity-1 {
    opacity:0.2!important;
}
.opacity-2 {
    opacity:0.4!important;
}
.opacity-3 {
    opacity:0.6!important;
}
.opacity-4 {
    opacity:0.9!important;
}
.opacity-5 {
    opacity:1!important;
}