
html {
    scroll-behavior: smooth;
  }
#home {
    height: 65.3vw;
    min-height: 600px;
    background-color: #6a5acd;
}
@media screen and (min-width: 768px) {

    #go-top-button {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    #home {
        height: 65.3vw;
    }
    #go-top-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
        height: 70px;
        width: 70px;
        border-radius: 50%;
        text-align: center;
        line-height: 60px;
        font-size: 40px;
    }


}

#name-img {
    height: 40vw;
    align-self: center;
    max-height: 600px;
}

@media screen and (min-width: 1600px) {
    #name-img {

        align-self: start;
        margin-top: 20vh;
    }
}


@media screen and (max-width: 550px) {
    #name-img {
        width: 90%;
        height: auto;
        margin-left: 2%;
    }
}